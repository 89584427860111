import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row, notification } from 'antd';

// Components
import { BreadcrumbContainer } from 'components/Breadcrumb';
import { CompanyRepresentatives } from 'components/CompanyRepresentatives';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { EscrowIcon, EscrowRoutes } from 'modules/escrow/constants/routes';
import { PageActions } from 'components/PageActions';
import { PageHeaderWithButton } from 'components/PageHeader/PageHeader';
import { PersonRepresentatives } from 'components/PersonRepresentatives';
import { TextM } from 'components/Text';
import Confirmation from 'components/Icons/Confirmation';
import CreatingConfirmationComponent from './CreatingConfirmation/CreatingConfirmation';
import DestinationCreationModal from 'modules/escrow/components/DestinationCreationModal/DestinationCreationModal';
import EscrowCreationRelatedParts from './RelatedParts/EscrowCreationRelatedParts';
import EscrowCreationSigners from './Signers/EscrowCreationSigners';
import InformationComponent from './Information/Information';
import StepsButtonComponent from 'modules/escrow/components/StepsButton/StepsButton';
import StepsComponent from 'modules/escrow/components/steps/Steps';

// Hooks and services
import { AccountsService } from 'modules/escrow/services';
import { useBreadcrumb } from 'modules/core/context/AppContext';
import { useDestinations } from 'modules/escrow/hooks';
import { useEscrowAccountsContext } from 'modules/escrow/context/EscrowAccounts.context';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';

// Types and helpers
import { AccountRelatedPart, AccountType } from 'types/Account';
import { ApiError } from 'types/ApiError';
import { Destination } from 'types/Destination';
import { isPersonOrCompany } from 'helpers/validators';
import { Creator } from 'types/Creator';
import { AccessType } from 'types/Access';
import {
	BusinessType,
	CompanyDto,
	EClientType,
	PersonType,
} from 'types/Company';

// Styles
import { PageEscrowWrapper, Steps, StepsWrapper, StyledSteps } from './styles';

const enum EEscrowSteps {
	INFORMATION = 'INFORMATION',
	REPRESENTATIVES = 'REPRESENTATIVES',
	PARTS = 'PARTS',
	SIGNERS = 'SIGNERS',
	CONFIRMATION = 'CONFIRMATION',
}

const EscrowCreationPage = () => {
	/* Hooks */
	const navigate = useNavigate();
	const { id } = useParams();
	const [api, contextHolder] = notification.useNotification();
	const { setBreadcrumb } = useBreadcrumb();
	const { type } = useCurrentProfile();
	const {
		company,
		setIsPersonCreating,
		isInformationFormValid,
		isPJDocumentsValid,
		relatedParts,
	} = useEscrowAccountsContext();

	/* States */
	const [newAccountId, setNewAccountId] = useState<string>();
	const [progressBar, setProgressBar] = useState<number>(20);
	const [accountDestinations, setAccountDestinations] =
		useState<Destination[]>();
	const [isOpenSuccessModal, setIsOpenSuccessModal] =
		useState<boolean>(false);
	const [isOpenBeneficiaryModal, setIsOpenBeneficiaryModal] =
		useState<boolean>(false);
	const [currentStep, setCurrentStep] = useState<EEscrowSteps>(
		EEscrowSteps.INFORMATION,
	);

	const isIF = type === AccessType.FINANCIAL_INSTITUTION;
	const isPF =
		isPersonOrCompany(company?.taxpayer_id || '') === EClientType.PF;

	const { mutate } = useMutation<
		{ id: string },
		ApiError,
		{
			company: CompanyDto;
			relatedParts: AccountRelatedPart[];
		}
	>({
		mutationFn: ({ company: newCompany, relatedParts }) => {
			return AccountsService.createAccount({
				person_id: newCompany?.id || '',
				account_type: AccountType.ESCROW,
				created_by_person_id: isIF
					? newCompany.created_by_person_id || undefined
					: undefined,
				parties: relatedParts.map(relatedPart => {
					if (relatedPart.person.id === 'company_owner_id') {
						return {
							...relatedPart,
							person: {
								...relatedPart.person,
								id: newCompany.id as string,
							},
						};
					}
					return relatedPart;
				}),
			});
		},
		onSuccess: ({ id }) => {
			setNewAccountId(id);
			setIsPersonCreating(false);
			setIsOpenSuccessModal(true);
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao criar a conta.',
			});
		},
	});

	const createAccountHandler = useCallback(() => {
		mutate({
			company: {
				...company,
				person_type: PersonType.LEGAL,
				documents: {
					...company?.documents,
					bylaws: company?.documents?.bylaws,
					income_statement: company?.documents?.income_statement,
					incorporation_certificate:
						company?.documents?.incorporation_certificate,
					last_contract_amendment:
						company?.documents?.last_contract_amendment,
					proof_of_address: company?.documents?.proof_of_address,
					power_of_attorney: company?.documents?.power_of_attorney,
					document_identification:
						company?.documents?.document_identification,
				},
				average_monthly_revenue: Number(
					company?.average_monthly_revenue,
				),
			},
			relatedParts: relatedParts.map<AccountRelatedPart>(relatedPart => ({
				person: {
					businessType: relatedPart.person?.business_type?.includes(
						'*',
					)
						? BusinessType.OTHERS
						: relatedPart.person?.business_type ||
							BusinessType.OTHERS,
					id: relatedPart.person?.id || '',
				},
				sign_contract: relatedPart?.sign_contract || false,
				permissions: relatedPart.permission,
			})),
		});
	}, [relatedParts, company]);

	const nextStepHandler = useCallback(() => {
		if (currentStep === EEscrowSteps.CONFIRMATION) {
			return createAccountHandler();
		}

		const nextStep = {
			[EEscrowSteps.INFORMATION]: EEscrowSteps.REPRESENTATIVES,
			[EEscrowSteps.REPRESENTATIVES]: EEscrowSteps.PARTS,
			[EEscrowSteps.PARTS]: EEscrowSteps.SIGNERS,
			[EEscrowSteps.SIGNERS]: EEscrowSteps.CONFIRMATION,
			[EEscrowSteps.CONFIRMATION]: EEscrowSteps.CONFIRMATION,
		};

		setCurrentStep(nextStep[currentStep]);
	}, [currentStep]);

	const backStepHandler = useCallback(() => {
		const nextStep = {
			[EEscrowSteps.INFORMATION]: EEscrowSteps.INFORMATION,
			[EEscrowSteps.REPRESENTATIVES]: EEscrowSteps.INFORMATION,
			[EEscrowSteps.PARTS]: EEscrowSteps.REPRESENTATIVES,
			[EEscrowSteps.SIGNERS]: EEscrowSteps.PARTS,
			[EEscrowSteps.CONFIRMATION]: EEscrowSteps.SIGNERS,
		};

		setCurrentStep(nextStep[currentStep]);
	}, [currentStep]);

	const onCloseSuccessModal = () => {
		setIsOpenSuccessModal(false);
		setIsOpenBeneficiaryModal(false);
		navigate(EscrowRoutes.ESCROW_BASE);
	};

	const stepNumbers = {
		[EEscrowSteps.INFORMATION]: 1,
		[EEscrowSteps.REPRESENTATIVES]: 2,
		[EEscrowSteps.PARTS]: 3,
		[EEscrowSteps.SIGNERS]: 4,
		[EEscrowSteps.CONFIRMATION]: 5,
	};

	const steps = {
		[EEscrowSteps.INFORMATION]: <InformationComponent id={id} />,
		[EEscrowSteps.REPRESENTATIVES]: isPF ? (
			<PersonRepresentatives
				isAccountCreation
				person={{
					...company,
					creator: {} as Creator,
					id: company?.id || '',
				}}
			/>
		) : (
			<CompanyRepresentatives
				isAccountCreation
				company={{
					...company,
					creator: {} as Creator,
					id: company?.id || '',
				}}
			/>
		),
		[EEscrowSteps.PARTS]: <EscrowCreationRelatedParts />,
		[EEscrowSteps.SIGNERS]: <EscrowCreationSigners />,
		[EEscrowSteps.CONFIRMATION]: <CreatingConfirmationComponent />,
	};

	const isDisabled =
		currentStep === EEscrowSteps.SIGNERS &&
		(!relatedParts.length || !company?.id);

	const getAccountsHandler = useCallback(async () => {
		if (!!newAccountId) {
			const response = await AccountsService.getAccount(newAccountId);

			if (response?.destinations) {
				setAccountDestinations(response?.destinations);
			}
			return;
		}

		api.error({
			description:
				'Adicione as contas beneficiárias em Detalhes da Conta',
			message: 'Ocorreu um problema ao buscar as contas beneficiárias.',
		});
	}, [newAccountId]);

	const { createOrUpdateDestination, deleteDestination, loading } =
		useDestinations(newAccountId || '', {
			onCreateSuccess: () => {
				getAccountsHandler();
			},
			onUpdateSuccess: () => {
				getAccountsHandler();
			},
			onDeleteSuccess: () => {
				getAccountsHandler();
			},
			onError: error => {
				api.error({
					description: error?.data?.message || '',
					message: 'Ocorreu um problema com conta beneficiária.',
				});
			},
		});

	useEffect(() => {
		const progressBar = {
			[EEscrowSteps.INFORMATION]: 20,
			[EEscrowSteps.REPRESENTATIVES]: 40,
			[EEscrowSteps.PARTS]: 60,
			[EEscrowSteps.SIGNERS]: 80,
			[EEscrowSteps.CONFIRMATION]: 100,
		};

		setProgressBar(progressBar[currentStep]);
	}, [currentStep]);

	useEffect(() => {
		setBreadcrumb([
			{
				href: '/escrow',
				title: (
					<BreadcrumbContainer>
						<EscrowIcon />
						<span>Conta Escrow</span>
					</BreadcrumbContainer>
				),
			},
			{
				title: 'Criar Conta Escrow',
			},
		]);
	}, [setBreadcrumb]);

	return (
		<>
			<PageHeaderWithButton
				title="Criar Conta Escrow"
				progressBarPercent={progressBar}
				isSticky
			>
				<PageActions>
					<Steps>{`Etapa ${stepNumbers[currentStep]}/5`}</Steps>
				</PageActions>
			</PageHeaderWithButton>
			{contextHolder}
			<StepsWrapper>
				<StyledSteps>
					<StepsComponent
						steps={[
							{
								title: 'Cliente',
								action: () =>
									setCurrentStep(EEscrowSteps.INFORMATION),
								checked:
									currentStep === EEscrowSteps.INFORMATION,
								completed: !!company?.id,
							},
							{
								title: isPF ? 'Procurador' : 'Representantes',
								action: () =>
									setCurrentStep(
										EEscrowSteps.REPRESENTATIVES,
									),
								checked:
									currentStep ===
									EEscrowSteps.REPRESENTATIVES,
								completed: isPF
									? [
											EEscrowSteps.CONFIRMATION,
											EEscrowSteps.PARTS,
											EEscrowSteps.SIGNERS,
										].includes(currentStep)
									: !!company?.representatives?.length,
							},
							{
								title: 'Partes relacionadas',
								action: () =>
									setCurrentStep(EEscrowSteps.PARTS),
								checked: currentStep === EEscrowSteps.PARTS,
								completed:
									[
										EEscrowSteps.CONFIRMATION,
										EEscrowSteps.SIGNERS,
									].includes(currentStep) && !!company?.id,
							},
							{
								title: 'Assinantes',
								action: () =>
									setCurrentStep(EEscrowSteps.SIGNERS),
								checked: currentStep === EEscrowSteps.SIGNERS,
								completed:
									currentStep === EEscrowSteps.CONFIRMATION,
							},
							{
								title: 'Está tudo certo?',
								action: () => {
									if (
										!!relatedParts.length &&
										!!company?.representatives?.length &&
										isInformationFormValid &&
										isPJDocumentsValid
									) {
										setCurrentStep(
											EEscrowSteps.CONFIRMATION,
										);
									}

									api.warning({
										description:
											'Por favor, preencha todos as etapas para prosseguir.',
										message: 'Etapa não preenchida',
									});
								},
								checked:
									currentStep === EEscrowSteps.CONFIRMATION,
								completed: !!isOpenBeneficiaryModal,
							},
						]}
					/>
				</StyledSteps>

				<PageEscrowWrapper>{steps[currentStep]}</PageEscrowWrapper>
			</StepsWrapper>

			<StepsButtonComponent
				backStep={backStepHandler}
				isDisabled={isDisabled}
				isFirstStep={currentStep !== EEscrowSteps.INFORMATION}
				isLastStep={currentStep === EEscrowSteps.CONFIRMATION}
				nextStep={nextStepHandler}
			/>

			<ConfirmationModal
				isOpen={!!isOpenSuccessModal}
				title="Abertura de conta solicitada!"
				confirmText="Adicionar contas beneficiárias."
				cancelText="Não desejo adicionar agora."
				onClose={onCloseSuccessModal}
				onCancel={onCloseSuccessModal}
				onConfirm={() => {
					setIsOpenSuccessModal(false);
					setIsOpenBeneficiaryModal(true);
				}}
			>
				<Row
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Col span={3}>
						<Confirmation />
					</Col>
					<Col span={21}>
						<TextM>
							A abertura de sua conta foi solicitada e será
							analisada pela Celcoin.
						</TextM>
					</Col>
				</Row>
			</ConfirmationModal>

			<DestinationCreationModal
				isOpen={!!isOpenBeneficiaryModal}
				isLoading={loading}
				destinations={accountDestinations}
				onClose={() => {
					onCloseSuccessModal();
				}}
				onCreateOrUpdate={destination => {
					createOrUpdateDestination(destination);
				}}
				onDelete={deleteDestination}
			/>
		</>
	);
};

export default EscrowCreationPage;
