import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import { ApiError } from 'types/ApiError';
import { Destination, DestinationDto } from 'types/Destination';
import { DestinationsService } from '../services/destinations';

export const useDestinations = (
	accountId: string,
	options?: {
		onError?: (error: ApiError) => void;
		onCreateSuccess?: (destination: Destination) => void;
		onUpdateSuccess?: (destination: Destination) => void;
		onDeleteSuccess?: (destinationId: string) => void;
	},
) => {
	const { mutate: updateMutate, isPending: isUpdating } = useMutation<
		void,
		ApiError,
		Destination
	>({
		mutationFn: destination => {
			const { id: destinationId, ...destinationDto } = destination;
			return DestinationsService.updateDestination(
				accountId,
				destinationId,
				destinationDto,
			);
		},
		onSuccess: (r, destination) => {
			if (typeof options?.onUpdateSuccess === 'function') {
				options.onUpdateSuccess(destination);
			}
		},
		onError: e => {
			if (typeof options?.onError === 'function') {
				options.onError(e);
			}
		},
	});

	const { mutate: createMutate, isPending: isCreating } = useMutation<
		{ id: string },
		ApiError,
		DestinationDto
	>({
		mutationFn: destinationDto => {
			return DestinationsService.createDestination(
				accountId,
				destinationDto,
			);
		},
		onSuccess: ({ id }, destination) => {
			if (typeof options?.onCreateSuccess === 'function') {
				options.onCreateSuccess({
					...destination,
					id,
				});
			}
		},
		onError: e => {
			if (typeof options?.onError === 'function') {
				options.onError(e);
			}
		},
	});

	const { mutate: deleteMutate, isPending: isDeleting } = useMutation<
		void,
		ApiError,
		string
	>({
		mutationFn: destinationId => {
			return DestinationsService.deleteDestination(
				accountId,
				destinationId,
			);
		},
		onSuccess: (e, destinationId) => {
			if (typeof options?.onDeleteSuccess === 'function') {
				options.onDeleteSuccess(destinationId);
			}
		},
		onError: e => {
			if (typeof options?.onError === 'function') {
				options.onError(e);
			}
		},
	});

	const createOrUpdateDestination = useCallback(
		(destination: Destination | DestinationDto) => {
			if (!destination.id) {
				createMutate(destination);
				return;
			}

			// HK refatorar chamada
			updateMutate({ ...destination, id: destination.id || '' });
		},
		[createMutate, updateMutate],
	);

	const deleteDestination = useCallback(
		(id: string) => {
			deleteMutate(id);
		},
		[deleteMutate],
	);

	return {
		loading: isUpdating || isCreating || isDeleting,
		createOrUpdateDestination,
		deleteDestination,
	};
};
