import {
	StyledStatusColor,
	StyledStatusWrapper,
	StyledWrapperChip,
} from './Chip.styles';

interface IChipComponentProps extends IChildrenProps {
	backgroundColor: string;
	statusColor?: string;
	alignText: 'center' | 'flex-start' | 'flex-end' | 'space-between';
	shadow?: boolean;
}

const ChipComponent = ({
	backgroundColor,
	alignText,
	statusColor,
	children,
	shadow,
}: IChipComponentProps) => {
	return (
		<StyledWrapperChip
			color={backgroundColor}
			justify={alignText}
			shadow={shadow!!}
		>
			{statusColor && (
				<StyledStatusWrapper>
					<StyledStatusColor color={statusColor} />
				</StyledStatusWrapper>
			)}
			{children}
		</StyledWrapperChip>
	);
};

export default ChipComponent;
