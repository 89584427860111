import {
	Button,
	Card,
	Col,
	Divider,
	Form,
	Input,
	InputNumber,
	Row,
	Select,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Destination, DestinationType } from 'types/Destination';
import { cpfCnpjRule, fullNameRule } from 'helpers/rules';
import { BankListDescription, BankListOptions } from 'constants/banksList';
import { DestinationTypeDescription } from 'constants/destination';
import { normalizeBankAccount, normalizeCpfCnpj } from 'helpers/normalizers';
import { serializeOnlyNumbers } from 'helpers/serializers';
import { Modal } from 'components/Modal';
import { useTheme } from 'styled-components';
import { Heading2 } from 'components/Heading2';
import { TextM, TextS, TextSS } from 'components/Text';
import { BiPencil, BiTrash } from 'react-icons/bi';
import {
	Footer,
	StyledActions,
	StyledDestination,
	StyledEditDestination,
	StyledRemoveDestination,
} from './styles';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { useEffect, useState } from 'react';

interface IDestinationCreationModalProps {
	isOpen: boolean;
	isLoading?: boolean;
	destinations?: Destination[];
	onClose: () => void;
	onCreateOrUpdate: (destination: Destination) => void;
	onDelete: (destinationId: string) => void;
}

const DestinationCreationModal = ({
	destinations,
	isLoading,
	isOpen,
	onClose,
	onCreateOrUpdate,
	onDelete,
}: IDestinationCreationModalProps) => {
	const theme = useTheme();
	const [form] = useForm();

	const [selectedDestination, setSelectedDestination] =
		useState<Destination>();
	const [isOpenConfirmModal, setIsOpenConfirmModal] =
		useState<boolean>(false);

	useEffect(() => {
		if (!selectedDestination) {
			form.resetFields();
		}
	}, [selectedDestination]);

	return (
		<>
			<Modal
				title="Adicionar Conta Beneficiária"
				isOpen={isOpen}
				onClose={onClose}
				width={1000}
			>
				<Row gutter={[16, 16]}>
					<Col span={12}>
						<Heading2 style={{ marginBottom: '10px' }}>
							Contas adicionadas:
						</Heading2>
						<Card
							style={{
								background: theme.background,
								maxHeight: '30rem',
								overflowY: 'auto',
							}}
						>
							{!!destinations ? (
								destinations.map((destination, index) => (
									<div key={destination.id}>
										<StyledDestination>
											<div>
												<TextSS
													color={theme.textSecondary}
												>
													<b>{destination.name}</b> -{' '}
													{normalizeCpfCnpj(
														destination.taxpayer_id,
													)}
												</TextSS>
												<TextSS
													color={theme.textSecondary}
												>
													{
														BankListDescription[
															destination.bank
														]
													}
												</TextSS>
												<TextSS
													color={theme.textSecondary}
												>
													Agência:{' '}
													{destination.branch} -{' '}
													{
														DestinationTypeDescription[
															destination.type
														]
													}
													:{' '}
													{normalizeBankAccount(
														destination.account,
													)}
												</TextSS>
											</div>
											<StyledActions>
												{/* Edit */}
												<StyledEditDestination
													onClick={() => {
														setSelectedDestination(
															destination,
														);
														form.setFieldsValue(
															destination,
														);
													}}
												>
													<BiPencil />
												</StyledEditDestination>
												{/* Delete */}
												<StyledRemoveDestination
													onClick={() => {
														setSelectedDestination(
															destination,
														);
														setIsOpenConfirmModal(
															true,
														);
													}}
												>
													<BiTrash />
												</StyledRemoveDestination>
											</StyledActions>
										</StyledDestination>
										{index !== destinations.length - 1 && (
											<Divider />
										)}
									</div>
								))
							) : (
								<TextS color={theme.textGray}>
									Nenhuma conta beneficiária adicionada.
								</TextS>
							)}
						</Card>
					</Col>
					<Col span={12}>
						<Heading2 style={{ marginBottom: '10px' }}>
							Adicione a conta beneficiária
						</Heading2>
						<Card>
							<Form<Destination>
								style={{ width: '100%' }}
								initialValues={selectedDestination}
								layout="vertical"
								form={form}
								onFinish={values => {
									onCreateOrUpdate({
										...values,
										taxpayer_id: serializeOnlyNumbers(
											values.taxpayer_id,
										),
										account: serializeOnlyNumbers(
											values.account,
										),
										branch: values.branch.toString(),
										id: selectedDestination?.id || '',
									});
									setSelectedDestination(undefined);
									form.resetFields();
								}}
							>
								<Row gutter={[16, 16]}>
									<Col span={24}>
										<Form.Item
											name="taxpayer_id"
											label="Documento do beneficiário"
											normalize={value =>
												normalizeCpfCnpj(value)
											}
											rules={[
												{
													required: true,
													message:
														'Campo obrigatório',
												},
												cpfCnpjRule,
											]}
										>
											<Input placeholder="CPF/CNPJ" />
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={[16, 16]}>
									<Col span={24}>
										<Form.Item
											name="name"
											label="Nome do beneficiário"
											rules={[
												{
													required: true,
													message:
														'Campo obrigatório',
												},
												fullNameRule('name'),
											]}
										>
											<Input placeholder="Ex.: João da Silva" />
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={[16, 16]}>
									<Col span={24}>
										<Form.Item
											name="bank"
											label="Banco"
											rules={[
												{
													required: true,
													message:
														'Campo obrigatório',
												},
											]}
										>
											<Select
												showSearch={true}
												filterOption={(input, option) =>
													!!option?.label
														?.toLowerCase()
														?.includes(
															input.toLowerCase(),
														)
												}
												placeholder="Selecione"
												options={BankListOptions}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<Form.Item
											name="branch"
											label="Agência (sem dígito)"
											rules={[
												{
													required: true,
													message:
														'Campo obrigatório',
												},
											]}
										>
											<InputNumber
												placeholder="0000"
												min={0}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item
											name="account"
											label="Conta"
											normalize={value =>
												normalizeBankAccount(value)
											}
											rules={[
												{
													required: true,
													message:
														'Campo obrigatório',
												},
											]}
										>
											<Input
												placeholder="000000-0"
												min={0}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={[16, 16]}>
									<Col span={24}>
										<Form.Item
											name="type"
											label="Tipo de conta"
											rules={[
												{
													required: true,
													message:
														'Campo obrigatório',
												},
											]}
										>
											<Select
												placeholder="Selecione"
												options={Object.keys(
													DestinationTypeDescription,
												).map(key => ({
													label: DestinationTypeDescription[
														key as DestinationType
													],
													value: key,
												}))}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Footer>
									<Button
										type="primary"
										loading={isLoading}
										disabled={false}
										htmlType="submit"
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											gap: '0.5rem',
										}}
									>
										{!!selectedDestination?.id &&
										!isOpenConfirmModal
											? 'Editar'
											: 'Adicionar'}
									</Button>
								</Footer>
							</Form>
						</Card>
					</Col>
				</Row>
			</Modal>

			<ConfirmationModal
				isOpen={isOpenConfirmModal}
				danger={true}
				title="Remover conta beneficiária"
				confirmText="Remover conta"
				cancelText="Cancelar"
				onConfirm={() => {
					onDelete(selectedDestination?.id || '');
					setSelectedDestination(undefined);
					setIsOpenConfirmModal(false);
				}}
				onCancel={() => setIsOpenConfirmModal(false)}
				onClose={() => setIsOpenConfirmModal(false)}
			>
				<TextM weight="normal">
					Você tem certeza que deseja remover essa conta beneficiária?
				</TextM>
			</ConfirmationModal>
		</>
	);
};

export default DestinationCreationModal;
