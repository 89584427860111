import { Button, Flex } from 'antd';
import { EscrowAccountOutlinedIcon } from 'components/Icons/EscrowAccount';
import { FinanceIcon } from 'components/Icons/Finance';
import { Modal } from 'components/Modal';
import { TextS } from 'components/Text';
import { useNavigate } from 'react-router-dom';
import theme from 'theme/theme';

interface IRedirectModalProps {
	setIsOpenModal: (value: boolean) => void;
	isOpenModal: boolean;
	taxpayer_id?: string;
}

const RedirectModal = ({
	setIsOpenModal,
	isOpenModal,
	taxpayer_id,
}: IRedirectModalProps) => {
	const navigate = useNavigate();

	return (
		<Modal
			title="Que tipo de conta deseja criar?"
			isOpen={isOpenModal}
			onClose={() => setIsOpenModal(false)}
		>
			<Flex align="flex-start">
				<Button
					type="text"
					onClick={() =>
						navigate(`/payment-account/new-company/${taxpayer_id}`)
					}
				>
					<FinanceIcon />
					<TextS color={theme.primary}> Conta Livre</TextS>
				</Button>
				<Button
					type="text"
					onClick={() => navigate(`/escrow/new/${taxpayer_id}`)}
				>
					<EscrowAccountOutlinedIcon color={theme.primary} />
					<TextS color={theme.primary}> Conta Escrow</TextS>
				</Button>
			</Flex>
		</Modal>
	);
};

export default RedirectModal;
