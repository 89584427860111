import { useEffect } from 'react';
import { Col, DatePicker, Form, Input, Row, Select, notification } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { PiSpinnerBold } from 'react-icons/pi';

// Components
import { Spinner } from 'components/UploaderArea/styles';

// Hooks and services
import { useCompany } from 'modules/company/hooks';

// Types and helpers
import { CompanyDto, PersonType } from 'types/Company';
import { serializeOnlyNumbers } from 'helpers/serializers';
import { NaturalPersonCreatingForm } from '../types';
import { normalizeCpf, normalizePhone } from 'helpers/normalizers';
import {
	cpfRule,
	emailRule,
	fullNameRule,
	maxTodayDateRule,
	minDateRule,
	phoneRule,
} from 'helpers/rules';
import { validateCpf } from 'helpers/validators';
import { useAutoCompletePersonData } from 'modules/company/hooks/useAutoCompletePersonData';
import dayjs from 'dayjs';

interface INaturalPersonCreationProps {
	company?: CompanyDto;
	setCompany: (company: CompanyDto) => void;
	taxId?: string;
	isFormDisabled?: boolean;
	isRepresentativeFormCreation?: boolean;
}

const NaturalPersonCreationContainer = ({
	company,
	setCompany,
	taxId,
	isFormDisabled,
	isRepresentativeFormCreation,
}: INaturalPersonCreationProps) => {
	/* Hooks */
	const [form] = Form.useForm();
	const [api, contextHolder] = notification.useNotification();

	const handleGetPersonData = (taxpayer_id: string) => {
		getPersonData({
			taxpayer_id,
			person_type: PersonType.NATURAL,
		});
	};

	const { getPersonData } = useAutoCompletePersonData(
		{
			onError: e => {
				console.error(e);
				api.error({
					description: 'Por favor preencha os campos manualmente.',
					message:
						'Ocorreu um problema ao buscar informações da pessoa.',
				});
			},
			onSuccess: personData => {
				if ('mother_name' in personData) {
					const values = {
						mother_name: personData.mother_name,
						name: personData.name,
						birth_date: dayjs(
							personData.birth_date?.split('T')?.[0],
						),
					};
					form.setFieldsValue(values);

					setCompany({
						...company,
						...values,
						birth_date: values.birth_date?.format('YYYY-MM-DD'),
					});

					return;
				}

				throw new Error('Invalid person data');
			},
		},
		[company],
	);

	const { getCompanyByTaxpayerId, loading } = useCompany({
		onError: e => {
			api.error({
				description: e.data?.message,
				message: 'Ocorreu um problema ao buscar informações da pessoa.',
			});
		},
		onSuccess: newPerson => {
			// Se estiver criando em Clientes (!taxId) e retornar uma person:
			if (!taxId && newPerson?.id) {
				api.error({
					message: 'Pessoa já cadastrada',
					description: `${newPerson?.name || 'Pessoa'} já está cadastrada no sistema.`,
				});
				return;
			}

			handleGetPersonData(
				serializeOnlyNumbers(form.getFieldValue('taxpayer_id')),
			);
		},
	});

	useEffect(() => {
		if (company?.taxpayer_id?.length === 11) {
			handleGetPersonData(serializeOnlyNumbers(company?.taxpayer_id));
		}
	}, []);

	return (
		<>
			{contextHolder}
			<Form<NaturalPersonCreatingForm>
				form={form}
				layout="vertical"
				initialValues={
					{
						...company,
						taxpayer_id: normalizeCpf(
							taxId ? taxId : company?.taxpayer_id || '',
						),
						birth_date: company?.birth_date
							? dayjs(company.birth_date)
							: undefined,
					} as CompanyDto
				}
				onFieldsChange={f => {
					setCompany({ ...company, [f[0].name]: f[0].value });
				}}
			>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<FormItem
							style={{ padding: 0, margin: 0 }}
							shouldUpdate={(prevValues, currentValues) =>
								prevValues.taxpayer_id !==
								currentValues.taxpayer_id
							}
						>
							{({ getFieldValue }) => {
								return (
									<Form.Item
										name="taxpayer_id"
										label="CPF"
										normalize={e => normalizeCpf(e)}
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
											cpfRule,
										]}
									>
										<Input
											placeholder="000.000.000-00"
											disabled={isFormDisabled}
											onBlur={() => {
												const taxpayerId =
													getFieldValue(
														'taxpayer_id',
													);

												if (
													validateCpf(taxpayerId) &&
													!isRepresentativeFormCreation
												) {
													getCompanyByTaxpayerId(
														serializeOnlyNumbers(
															taxpayerId,
														),
													);
												}
											}}
											suffix={
												loading ? (
													<Spinner>
														<PiSpinnerBold
															style={{
																fontSize: 24,
															}}
														/>
													</Spinner>
												) : null
											}
										/>
									</Form.Item>
								);
							}}
						</FormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={18}>
						<Form.Item
							name="name"
							label="Nome completo"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								fullNameRule('name'),
							]}
						>
							<Input
								placeholder="Digite aqui"
								disabled={isFormDisabled}
							/>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							name="birth_date"
							label="Data de Nascimento"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								maxTodayDateRule('birth_date'),
								minDateRule,
							]}
						>
							<DatePicker
								disabled={isFormDisabled}
								placeholder="DD/MM/AAAA"
								format="DD/MM/YYYY"
								style={{ height: '2.5rem' }}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={12}>
						<Form.Item
							name="mother_name"
							label="Nome da mãe"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								fullNameRule('mother_name'),
							]}
						>
							<Input
								placeholder="Digite aqui"
								disabled={isFormDisabled}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="email_address"
							label="E-mail"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								emailRule('email_address'),
							]}
						>
							<Input
								placeholder="Digite aqui"
								disabled={isFormDisabled}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={12}>
						<Form.Item
							name="pep"
							label="Pessoa Politicamente Exposta (PEP)"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Select
								disabled={isFormDisabled}
								placeholder="Selecione"
								options={[
									{ label: 'Sim', value: true },
									{ label: 'Não', value: false },
								]}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="phone"
							label="Telefone"
							normalize={e => normalizePhone(e)}
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								phoneRule('phone'),
							]}
						>
							<Input
								placeholder="(00) 00000-0000"
								disabled={isFormDisabled}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</>
	);
};

export default NaturalPersonCreationContainer;
