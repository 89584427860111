import styled from 'styled-components';

export const DescriptionItem = styled.p<{ color?: string }>`
	padding: 0;
	margin: 0 0;
	font-size: 1.2rem;
	display: inline;
	vertical-align: sub;
	color: ${p => p.color || p.theme.text};
`;

export const DescriptionsWrapper = styled.div`
	.ant-descriptions-item-label {
		color: ${({ theme }) => theme.primary} !important;
		font-size: 16px;
		font-weight: 400;
	}

	.ant-descriptions-item-container {
		p {
			color: ${({ theme }) => theme.textSecondary} !important;
			font-size: 16px;
		}
	}
`;
