import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Col, Row, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import { BreadcrumbContainer } from 'components/Breadcrumb';
import { CompanyRepresentatives } from 'components/CompanyRepresentatives';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { Destination } from 'types/Destination';
import DestinationCreationModal from 'modules/escrow/components/DestinationCreationModal/DestinationCreationModal';
import { PageActions } from 'components/PageActions';
import { PageHeaderWithButton } from 'components/PageHeader/PageHeader';
import { TextM } from 'components/Text';
import { PersonRepresentatives } from 'components/PersonRepresentatives';
import Confirmation from 'components/Icons/Confirmation';
import EscrowCreationSigners from '../EscrowCreation/Signers/EscrowCreationSigners';
import StepsComponent from 'modules/escrow/components/steps/Steps';
import StepsButtonComponent from 'modules/escrow/components/StepsButton/StepsButton';
import InformationComponent from '../EscrowCreation/Information/Information';
import CreatingConfirmationComponent from '../EscrowCreation/CreatingConfirmation/CreatingConfirmation';
import { EscrowIcon, EscrowRoutes } from 'modules/escrow/constants/routes';

// Hooks and services
import { AccountsService } from 'modules/escrow/services';
import { useBreadcrumb } from 'modules/core/context/AppContext';
import { useEscrowAccountsContext } from 'modules/escrow/context/EscrowAccounts.context';
import { useDestinations } from 'modules/escrow/hooks';

// Types and helpers
import { ApiError } from 'types/ApiError';
import { paymentAccountPermissions } from './types';
import { isPersonOrCompany } from 'helpers/validators';
import { Creator } from 'types/Creator';
import {
	BusinessType,
	CompanyDto,
	EClientType,
	PersonType,
} from 'types/Company';

// Styles
import {
	PageEscrowWrapper,
	Steps,
	StepsWrapper,
	StyledSteps,
} from '../EscrowCreation/styles';
import { AccountType } from 'types/Account';
import { AccessType } from 'types/Access';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';

const enum EPaymentSteps {
	INFORMATION = 'INFORMATION',
	REPRESENTATIVES = 'REPRESENTATIVES',
	SIGNERS = 'SIGNERS',
	CONFIRMATION = 'CONFIRMATION',
}

const PaymentAccountCreationPage = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [api, contextHolder] = notification.useNotification();
	const { setBreadcrumb } = useBreadcrumb();
	const { type } = useCurrentProfile();
	const {
		company,
		setIsPersonCreating,
		isInformationFormValid,
		isPJDocumentsValid,
		isCreationInfoComplete,
		relatedParts,
	} = useEscrowAccountsContext();

	const [newAccountId, setNewAccountId] = useState<string>();
	const [progressBar, setProgressBar] = useState<number>(20);
	const [isOpenSuccessModal, setIsOpenSuccessModal] =
		useState<boolean>(false);
	const [isOpenBeneficiaryModal, setIsOpenBeneficiaryModal] =
		useState<boolean>(false);
	const [accountDestinations, setAccountDestinations] =
		useState<Destination[]>();
	const [currentStep, setCurrentStep] = useState<EPaymentSteps>(
		EPaymentSteps.INFORMATION,
	);

	const isIF = type === AccessType.FINANCIAL_INSTITUTION;
	const isPF =
		isPersonOrCompany(company?.taxpayer_id || '') === EClientType.PF;

	const { mutate } = useMutation<
		{ id: string },
		ApiError,
		{
			company: CompanyDto;
		}
	>({
		mutationFn: ({ company: newCompany }) => {
			return AccountsService.createAccount({
				person_id: newCompany.id || '',
				account_type: AccountType.PAYMENT,
				created_by_person_id: isIF
					? newCompany?.created_by_person_id || undefined
					: undefined,
				parties: [
					{
						permissions: paymentAccountPermissions,
						sign_contract: true,
						person: {
							businessType: BusinessType.OTHERS,
							id: newCompany.id as string,
						},
					},
				],
			});
		},
		onSuccess: ({ id }) => {
			setNewAccountId(id);
			setIsPersonCreating(false);
			setIsOpenSuccessModal(true);
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao criar a conta.',
			});
		},
	});

	const nextStepHandler = useCallback(() => {
		if (currentStep === EPaymentSteps.CONFIRMATION) {
			return createAccount();
		}

		const nextStep = {
			[EPaymentSteps.INFORMATION]: EPaymentSteps.REPRESENTATIVES,
			[EPaymentSteps.REPRESENTATIVES]: EPaymentSteps.SIGNERS,
			[EPaymentSteps.SIGNERS]: EPaymentSteps.CONFIRMATION,
			[EPaymentSteps.CONFIRMATION]: EPaymentSteps.CONFIRMATION,
		};

		setCurrentStep(nextStep[currentStep]);
	}, [currentStep]);

	const backStepHandler = useCallback(() => {
		const nextStep = {
			[EPaymentSteps.INFORMATION]: EPaymentSteps.INFORMATION,
			[EPaymentSteps.REPRESENTATIVES]: EPaymentSteps.INFORMATION,
			[EPaymentSteps.SIGNERS]: EPaymentSteps.REPRESENTATIVES,
			[EPaymentSteps.CONFIRMATION]: EPaymentSteps.SIGNERS,
		};

		setCurrentStep(nextStep[currentStep]);
	}, [currentStep]);

	const onCloseSuccessModal = () => {
		setIsOpenSuccessModal(false);
		setIsOpenBeneficiaryModal(false);
		navigate(EscrowRoutes.ACCOUNT_BASE);
	};

	const createAccount = useCallback(() => {
		mutate({
			company: {
				...company,
				person_type: PersonType.LEGAL,
				documents: company?.documents,
			},
		});
	}, [relatedParts, company]);

	const stepNumbers = {
		[EPaymentSteps.INFORMATION]: 1,
		[EPaymentSteps.REPRESENTATIVES]: 2,
		[EPaymentSteps.SIGNERS]: 3,
		[EPaymentSteps.CONFIRMATION]: 4,
	};

	const steps = {
		[EPaymentSteps.INFORMATION]: <InformationComponent id={id} />,
		[EPaymentSteps.REPRESENTATIVES]: isPF ? (
			<PersonRepresentatives
				isAccountCreation
				person={{
					...company,
					creator: {} as Creator,
					id: company?.id || '',
				}}
			/>
		) : (
			<CompanyRepresentatives
				isAccountCreation
				company={{
					...company,
					creator: {} as Creator,
					id: company?.id || '',
				}}
			/>
		),
		[EPaymentSteps.SIGNERS]: <EscrowCreationSigners />,
		[EPaymentSteps.CONFIRMATION]: <CreatingConfirmationComponent />,
	};

	const isDisabled =
		currentStep === EPaymentSteps.SIGNERS &&
		((!isPF && !company?.representatives?.length) || !company?.id);

	const getAccountsHandler = useCallback(async () => {
		if (!!newAccountId) {
			const response = await AccountsService.getAccount(newAccountId);

			if (response?.destinations) {
				setAccountDestinations(response?.destinations);
			}
			return;
		}

		api.error({
			message: 'Ocorreu um problema ao buscar as contas beneficiárias.',
		});
	}, [newAccountId]);

	const { createOrUpdateDestination, deleteDestination, loading } =
		useDestinations(newAccountId || '', {
			onCreateSuccess: () => {
				getAccountsHandler();
			},
			onUpdateSuccess: () => {
				getAccountsHandler();
			},
			onDeleteSuccess: () => {
				getAccountsHandler();
			},
			onError: error => {
				api.error({
					description: error?.data?.message || '',
					message: 'Ocorreu um problema com conta beneficiária.',
				});
			},
		});

	useEffect(() => {
		if (!!isCreationInfoComplete) {
			setProgressBar(100);
			return;
		}

		const progressBar = {
			[EPaymentSteps.INFORMATION]: 25,
			[EPaymentSteps.REPRESENTATIVES]: 50,
			[EPaymentSteps.SIGNERS]: 75,
			[EPaymentSteps.CONFIRMATION]: 100,
		};

		setProgressBar(progressBar[currentStep]);
	}, [currentStep]);

	useEffect(() => {
		setBreadcrumb([
			{
				href: '/escrow',
				title: (
					<BreadcrumbContainer>
						<EscrowIcon />
						<span>Conta Livre</span>
					</BreadcrumbContainer>
				),
			},
			{
				title: 'Criar Conta Livre',
			},
		]);
	}, [setBreadcrumb]);

	return (
		<>
			<PageHeaderWithButton
				title="Criar Conta Livre"
				progressBarPercent={progressBar}
				isSticky
			>
				<PageActions>
					<Steps>{`Etapa ${stepNumbers[currentStep]}/4`}</Steps>
				</PageActions>
			</PageHeaderWithButton>
			{contextHolder}
			<StepsWrapper>
				<StyledSteps>
					<StepsComponent
						steps={[
							{
								title: 'Cliente',
								action: () =>
									setCurrentStep(EPaymentSteps.INFORMATION),
								checked:
									currentStep === EPaymentSteps.INFORMATION,
								completed: !!company?.id,
							},
							{
								title: isPF ? 'Procurador' : 'Representantes',
								action: () =>
									setCurrentStep(
										EPaymentSteps.REPRESENTATIVES,
									),
								checked:
									currentStep ===
									EPaymentSteps.REPRESENTATIVES,
								completed: isPF
									? [
											EPaymentSteps.CONFIRMATION,
											EPaymentSteps.SIGNERS,
										].includes(currentStep)
									: !!company?.representatives?.length,
							},
							{
								title: 'Assinantes',
								action: () =>
									setCurrentStep(EPaymentSteps.SIGNERS),
								checked: currentStep === EPaymentSteps.SIGNERS,
								completed:
									currentStep === EPaymentSteps.CONFIRMATION,
							},
							{
								title: 'Está tudo certo?',
								action:
									!!company?.representatives?.length &&
									isInformationFormValid &&
									isPJDocumentsValid
										? () =>
												setCurrentStep(
													EPaymentSteps.CONFIRMATION,
												)
										: undefined,
								checked:
									currentStep === EPaymentSteps.CONFIRMATION,
								completed: !!isOpenBeneficiaryModal,
							},
						]}
					/>
				</StyledSteps>
				<PageEscrowWrapper>{steps[currentStep]}</PageEscrowWrapper>
			</StepsWrapper>

			<StepsButtonComponent
				backStep={backStepHandler}
				isDisabled={isDisabled}
				isFirstStep={currentStep !== EPaymentSteps.INFORMATION}
				isLastStep={currentStep === EPaymentSteps.CONFIRMATION}
				nextStep={nextStepHandler}
			/>

			<ConfirmationModal
				isOpen={!!isOpenSuccessModal}
				title="Abertura de conta solicitada!"
				confirmText="Adicionar contas beneficiárias."
				cancelText="Não desejo adicionar agora."
				onClose={onCloseSuccessModal}
				onCancel={onCloseSuccessModal}
				onConfirm={() => {
					setIsOpenSuccessModal(false);
					setIsOpenBeneficiaryModal(true);
				}}
			>
				<Row
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Col span={3}>
						<Confirmation />
					</Col>
					<Col span={21}>
						<TextM>
							A abertura de sua conta foi solicitada e será
							analisada pela Celcoin.
						</TextM>
					</Col>
				</Row>
			</ConfirmationModal>

			<DestinationCreationModal
				isOpen={!!isOpenBeneficiaryModal}
				isLoading={loading}
				destinations={accountDestinations}
				onClose={() => {
					onCloseSuccessModal();
					setIsOpenBeneficiaryModal(false);
				}}
				onCreateOrUpdate={destination => {
					createOrUpdateDestination(destination);
				}}
				onDelete={deleteDestination}
			/>
		</>
	);
};

export default PaymentAccountCreationPage;
