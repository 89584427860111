import { useCallback, useEffect, useState } from 'react';
import { PageHeader } from 'components/PageHeader';
import { Heading1 } from 'components/Heading1';
import { PageWrapper } from 'components/PageWrapper';
import { Company, CompanyDto, ETenantType, PersonType } from 'types/Company';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Col, notification } from 'antd';
import { AccountsService } from 'modules/escrow/services';
import { useNavigate, useParams } from 'react-router-dom';
import { useEscrowAccountsContext } from 'modules/escrow/context/EscrowAccounts.context';
import { StepsWrapper } from '../EscrowCreation/styles';
import { InformationModal } from 'components/InformationModal';
import { CheckmarkIcon } from 'components/Icons/Checkmark';
import { TextL } from 'components/Text';
import { SplashScreen } from 'components/SplashScreen';
import { ApiError } from 'types/ApiError';
import { PersonsService } from 'modules/company/services';
import { useBreadcrumb } from 'modules/core/context/AppContext';
import { EscrowRoutes } from 'modules/escrow/constants/routes';
import { BreadcrumbContainer } from 'components/Breadcrumb';
import {
	getAccountRouteTypeIcon,
	getAccountRouteTypeName,
	useIsEscrowAccountRoute,
} from 'modules/escrow/utils/adminRoute';
import { CompanyInformation } from './CompanyInformation';
import { NaturalPersonInformation } from './NaturalPersonInformation';
import { serializeOnlyNumbers } from 'helpers/serializers';

const EscrowEditionPage = () => {
	const [api, contextHolder] = notification.useNotification();
	const { id } = useParams();
	const { setBreadcrumb } = useBreadcrumb();
	const { company, setCompany, setIsEditingAccount } =
		useEscrowAccountsContext();
	const isEscrowRoute = useIsEscrowAccountRoute();
	const navigate = useNavigate();

	const [isOpenEditedAccountModal, setIsOpenEditedAccountModal] =
		useState(false);

	const redirectAccount = isEscrowRoute
		? EscrowRoutes.ESCROW_BASE
		: EscrowRoutes.ACCOUNT_BASE;
	const title = (
		<BreadcrumbContainer>
			{getAccountRouteTypeIcon()}
			<span>{getAccountRouteTypeName()}</span>
		</BreadcrumbContainer>
	);

	const { data, isLoading } = useQuery({
		queryKey: ['accountDetails', id],
		queryFn: () => AccountsService.getAccount(id!),
		refetchOnWindowFocus: false,
		enabled: typeof id === 'string',
	});

	const getCompanyById = useCallback(
		async (id: string): Promise<void> => {
			try {
				const response = await PersonsService.getCompany(id);

				setCompany(response);
			} catch (error) {
				console.error('Erro ao carregar dados da empresa', error);
			}
		},
		[data],
	);

	const closeModalRedirect = () => {
		setIsOpenEditedAccountModal(false);
		navigate(redirectAccount);
		setIsEditingAccount(false);
	};

	const { mutate } = useMutation<
		void,
		ApiError,
		Partial<CompanyDto | Company>
	>({
		mutationFn: companyDto => {
			return PersonsService.patchCompany(company?.id || '', companyDto);
		},
		onSuccess: () => {
			closeModalRedirect();
			api.success({
				message: 'Empresa atualizada com sucesso.',
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao atualizar a empresa.',
			});
		},
	});

	const editAccountHandler = useCallback(
		(editedCompany: Company | CompanyDto) => {
			const payload: CompanyDto = {
				address: {
					...editedCompany.address,
					postal_code: serializeOnlyNumbers(
						editedCompany.address?.postal_code,
					),
				},
				average_monthly_revenue: editedCompany.average_monthly_revenue,
				business_type: editedCompany.business_type,
				cnae: editedCompany.cnae,
				corporate_name: editedCompany.corporate_name,
				documents: editedCompany.documents,
				email_address: editedCompany.email_address,
				foundation_date: editedCompany.foundation_date,
				name: editedCompany.name,
				phone: editedCompany.phone,
				taxpayer_id: editedCompany.taxpayer_id,
				created_at: company?.created_at,
				person_type: company?.person_type,
				representatives: company?.representatives,
				tenant_type: ETenantType.DEFAULT,
			};

			mutate(payload);
		},
		[company],
	);

	useEffect(() => {
		if (!!data) {
			getCompanyById(data?.person_id);
			setBreadcrumb([
				{
					href: redirectAccount,
					title: title,
				},
				{
					title: 'Documentação pendente',
				},
			]);
		}
	}, [data]);

	if (isLoading || !company) {
		return <SplashScreen />;
	}

	return (
		<>
			<PageHeader>
				<Heading1>Documentos pendentes</Heading1>
			</PageHeader>
			{contextHolder}
			<StepsWrapper>
				<PageWrapper>
					{company?.person_type === PersonType.LEGAL ? (
						<CompanyInformation
							cnpj={data?.person.taxpayer_id}
							company={company}
							onNext={editAccountHandler}
						/>
					) : (
						<NaturalPersonInformation
							cnpj={data?.person.taxpayer_id}
							person={company}
							onNext={editAccountHandler}
						/>
					)}
				</PageWrapper>
			</StepsWrapper>

			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isOpenEditedAccountModal}
				title="Documentos enviados!"
				icon={<CheckmarkIcon />}
				onClose={closeModalRedirect}
			>
				<Col
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '0.3rem',
						marginLeft: '1rem',
					}}
				>
					<TextL weight="bold">
						As alterações no cadastro do titular foram efetuadas com
						sucesso!
					</TextL>
					<TextL weight="normal">
						Assim que sua solicitação de abertura for analisada,
						você receberá um e-mail.
					</TextL>
				</Col>
			</InformationModal>
		</>
	);
};

export default EscrowEditionPage;
