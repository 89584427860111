import { Tabs } from 'components/Tabs';
import { PageWrapper } from 'components/PageWrapper';
import { BreadcrumbContainer } from 'components/Breadcrumb';
import NaturalPersonDetailsUsers from './NaturalPersonDetailsUsers';
import { CompanyRoutes } from 'modules/company/constants/routes';
import { useQuery } from '@tanstack/react-query';
import { Navigate, useParams } from 'react-router-dom';
import { PersonsService } from 'modules/company/services';
import { SplashScreen } from 'components/SplashScreen';
import NaturalPersonDetailsInformation from './NaturalPersonDetailsInformation';
import { ManagementIcon } from 'modules/management/constants/routes';
import { PersonRepresentatives } from 'components/PersonRepresentatives';
import TemplateConfigContainer from 'modules/management/pages/TemplateConfig/TemplateConfig';
import { HiOutlineCog6Tooth } from 'react-icons/hi2';
import { RiFileList3Line } from 'react-icons/ri';
import { GoPeople, GoPerson } from 'react-icons/go';
import { PageHeaderWithButton } from 'components/PageHeader/PageHeader';
import { useState } from 'react';
import RedirectModal from '../../RedirectModal/RedirectModal';

const NaturalPersonDetailsPage = () => {
	const { id } = useParams();

	const [isOpenModal, setIsOpenModal] = useState(false);

	const { data, isLoading } = useQuery({
		queryKey: ['companyDetails', id],
		queryFn: () => PersonsService.getCompany(id!),
		refetchOnWindowFocus: false,
		enabled: typeof id === 'string',
	});

	if (isLoading) {
		return <SplashScreen />;
	}

	if (!data) {
		return <Navigate to={CompanyRoutes.BASE} />;
	}

	const breadcrumb = [
		{
			href: '/management/company',
			title: (
				<BreadcrumbContainer>
					<ManagementIcon />
					<span>Gestão</span>
				</BreadcrumbContainer>
			),
		},
		{
			title: data?.name,
		},
	];

	return (
		<>
			<PageHeaderWithButton
				isSticky
				title={data?.name || 'Conta pessoa física'}
				onClickButton={() => setIsOpenModal(true)}
				textButton="Criar conta"
			/>
			<PageWrapper isFluid={true}>
				<Tabs
					prePath={`/natural-person/${id}`}
					elements={[
						{
							title: 'Informações',
							path: 'info',
							breadcrumb: breadcrumb,
							content: (
								<NaturalPersonDetailsInformation
									person={data}
								/>
							),
							icon: (
								<RiFileList3Line
									size={20}
									style={{ marginBottom: '3px' }}
								/>
							),
						},
						{
							title: 'Procuradores',
							path: 'representatives',
							breadcrumb: breadcrumb,
							content: (
								<PersonRepresentatives
									person={data}
									isClientEdition={true}
								/>
							),
							icon: (
								<GoPerson
									size={20}
									style={{ marginBottom: '3px' }}
								/>
							),
						},
						{
							title: 'Acessos',
							path: 'access',
							breadcrumb: breadcrumb,
							content: (
								<NaturalPersonDetailsUsers company={data} />
							),
							icon: (
								<GoPeople
									size={20}
									style={{ marginBottom: '3px' }}
								/>
							),
						},
						{
							title: 'Configurações adicionais',
							path: 'config',
							breadcrumb: breadcrumb,
							content: <TemplateConfigContainer />,
							icon: (
								<HiOutlineCog6Tooth
									size={20}
									style={{ marginBottom: '3px' }}
								/>
							),
						},
					]}
				/>
			</PageWrapper>

			{isOpenModal && (
				<RedirectModal
					isOpenModal={isOpenModal}
					setIsOpenModal={setIsOpenModal}
					taxpayer_id={data.taxpayer_id}
				/>
			)}
		</>
	);
};

export default NaturalPersonDetailsPage;
