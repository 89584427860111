import { BreadcrumbContainer } from 'components/Breadcrumb';
import {
	EscrowAccountIcon,
	EscrowAccountOutlinedIcon,
} from 'components/Icons/EscrowAccount';
import React from 'react';
import { useTheme } from 'styled-components';

export const contaLivre = 'payment-account';

export enum EscrowRoutes {
	ESCROW_BASE = '/escrow',
	ESCROW_NEW = '/escrow/new',
	ESCROW_APPROVALS = '/escrow/approvals',
	ESCROW_ACCOUNTS = '/escrow/accounts',
	ACCOUNT_BASE = `/${contaLivre}`,
	ACCOUNT_NEW = `/${contaLivre}/new-company`,
	ACCOUNT_APPROVALS = `/${contaLivre}/approvals`,
	ACCOUNT_ACCOUNTS = `/${contaLivre}/accounts`,
}

export const EscrowIcon: React.FC<{ isActive?: boolean }> = ({ isActive }) => {
	const theme = useTheme();
	if (isActive) return <EscrowAccountIcon />;
	return <EscrowAccountOutlinedIcon color={theme.text} />;
};

export const getEscrowMenuBreadcrumb = (
	title: string,
	account?: string,
	id?: string,
) => {
	return [
		{
			href: '/escrow/accounts',
			title: (
				<BreadcrumbContainer>
					<EscrowIcon />
					<span>Conta Escrow</span>
				</BreadcrumbContainer>
			),
		},
		{
			title: `Conta ${account ?? ''}`,
			href: `/escrow/${id}/details`,
		},
		{
			title: title,
		},
	];
};

export const getEscrowBreadcrumb = (title: string) => {
	return [
		{
			href: '/escrow/accounts',
			title: (
				<BreadcrumbContainer>
					<EscrowIcon />
					<span>Conta Escrow</span>
				</BreadcrumbContainer>
			),
		},
		{
			title: title,
		},
	];
};
