import { useEffect } from 'react';
import { Col, DatePicker, Form, Input, Row, Select, notification } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { PiSpinnerBold } from 'react-icons/pi';

// Components
import { Spinner } from 'components/UploaderArea/styles';

// Hooks and services
import { useCompaniesList, useCompany } from 'modules/company/hooks';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';

// Types and helpers
import { CompanyTypeDescription } from 'constants/company';
import { BusinessType, CompanyDto, PersonType } from 'types/Company';
import { serializeOnlyNumbers } from 'helpers/serializers';
import { LegalPersonCreatingForm } from '../types';
import { normalizeCnpj, normalizePhone } from 'helpers/normalizers';
import {
	cnpjRule,
	emailRule,
	fullCompanyNameRule,
	maxTodayDateRule,
	phoneRule,
} from 'helpers/rules';
import { validateCnpj } from 'helpers/validators';
import { parsedCnaeData } from 'modules/company/utils/cnae';
import { companyRevenueOptions } from 'modules/company/utils/options';
import { AccessType } from 'types/Access';
import { useAutoCompletePersonData } from 'modules/company/hooks/useAutoCompletePersonData';
import dayjs from 'dayjs';

interface ILegalPersonProps {
	company?: CompanyDto;
	setCompany: (company: CompanyDto) => void;
	taxId?: string;
}

const LegalPersonContainer = ({
	company,
	setCompany,
	taxId,
}: ILegalPersonProps) => {
	/* Hooks */
	const [form] = Form.useForm();
	const [api, contextHolder] = notification.useNotification();
	const { type } = useCurrentProfile();
	const size = 1000;

	const { companies: managerCompanies } = useCompaniesList({
		initialFilters: {
			business_type: BusinessType.MANAGER,
			size: size,
		},
	});
	const { companies: custodianCompanies } = useCompaniesList({
		initialFilters: {
			business_type: BusinessType.CUSTODIAN,
			size: size,
		},
	});
	const { companies: adminCompanies } = useCompaniesList({
		initialFilters: {
			business_type: BusinessType.ADMINISTRATOR,
			size: size,
		},
	});

	const handleGetPersonData = (taxpayer_id: string) => {
		getPersonData({
			taxpayer_id,
			person_type: PersonType.LEGAL,
		});
	};

	const { getPersonData } = useAutoCompletePersonData(
		{
			onError: e => {
				console.error(e);
				api.error({
					description: 'Por favor preencha os campos manualmente.',
					message:
						'Ocorreu um problema ao buscar informações da pessoa.',
				});
			},
			onSuccess: personData => {
				if ('official_name' in personData) {
					const values = {
						name: personData.trade_name,
						corporate_name: personData.official_name,
						foundation_date: dayjs(
							personData.founded_date?.split('T')?.[0],
						),
						cnae: personData.cnae,
					};

					form.setFieldsValue(values);

					setCompany({
						...company,
						...values,
						foundation_date:
							values.foundation_date.format('YYYY-MM-DD'),
					});

					return;
				}

				throw new Error('Invalid person data');
			},
		},
		[company],
	);

	const { getCompanyByTaxpayerId, loading } = useCompany({
		onError: e => {
			api.error({
				description: e.data?.message,
				message: 'Ocorreu um problema ao buscar informações da pessoa.',
			});
		},
		onSuccess: newPerson => {
			// Se estiver criando em Clientes (!taxId) e retornar uma person:
			if (!taxId && newPerson?.id) {
				api.error({
					message: 'Pessoa já cadastrada',
					description: `${newPerson?.name || 'Pessoa'} já está cadastrada no sistema.`,
				});
				setCompany({} as CompanyDto);
				return;
			}

			handleGetPersonData(
				serializeOnlyNumbers(form.getFieldValue('taxpayer_id')),
			);
		},
	});

	useEffect(() => {
		if (company?.taxpayer_id) {
			handleGetPersonData(serializeOnlyNumbers(company?.taxpayer_id));
		}
	}, []);

	return (
		<>
			{contextHolder}
			<Form<LegalPersonCreatingForm>
				form={form}
				layout="vertical"
				initialValues={
					{
						...company,
						taxpayer_id: normalizeCnpj(
							taxId ? taxId : company?.taxpayer_id,
						),
						business_type:
							company?.business_type || BusinessType.OTHERS,
						foundation_date: company?.foundation_date
							? dayjs(company.foundation_date)
							: undefined,
					} as CompanyDto
				}
				onFieldsChange={f => {
					setCompany({ ...company, [f[0].name]: f[0].value });
				}}
			>
				{type === AccessType.FINANCIAL_INSTITUTION && (
					<>
						<Row gutter={[16, 16]}>
							<Col span={12}>
								<Form.Item
									name="business_type"
									label="Tipo"
									rules={[
										{
											required: true,
											message: 'Campo obrigatório',
										},
									]}
								>
									<Select
										placeholder="Selecione"
										options={Object.keys(BusinessType).map(
											key => ({
												label: CompanyTypeDescription[
													key as BusinessType
												],
												value: key,
											}),
										)}
									/>
								</Form.Item>
							</Col>
						</Row>
						{company?.business_type === BusinessType.FUND && (
							<Row gutter={[16, 16]}>
								<Col span={24}>
									<FormItem
										style={{
											padding: 0,
											margin: 0,
											minHeight: 0,
										}}
										shouldUpdate={(
											prevValues,
											currentValues,
										) =>
											prevValues.business_type !==
											currentValues.business_type
										}
									>
										{({ getFieldValue }) => (
											<Row gutter={[16, 16]}>
												{getFieldValue(
													'business_type',
												) === BusinessType.FUND && (
													<>
														<Col span={8}>
															<Form.Item
																name="fund_manager_id"
																label="Empresa Gestora"
																rules={[
																	{
																		required:
																			true,
																		message:
																			'Campo obrigatório',
																	},
																]}
															>
																<Select
																	placeholder="Selecione"
																	filterOption={(
																		input,
																		option,
																	) =>
																		option?.label
																			?.toLowerCase()
																			?.startsWith(
																				input.toLowerCase(),
																			) ||
																		String(
																			option?.value,
																		)
																			?.toLowerCase()
																			?.startsWith(
																				input.toLowerCase(),
																			)
																	}
																	options={managerCompanies.map(
																		company => ({
																			label: company.name,
																			value: company.id,
																		}),
																	)}
																	showSearch
																/>
															</Form.Item>
														</Col>
														<Col span={8}>
															<Form.Item
																name="fund_securitizer_id"
																label="Empresa Custodiante"
																rules={[
																	{
																		required:
																			true,
																		message:
																			'Campo obrigatório',
																	},
																]}
															>
																<Select
																	placeholder="Selecione"
																	filterOption={(
																		input,
																		option,
																	) =>
																		option?.label
																			?.toLowerCase()
																			?.startsWith(
																				input.toLowerCase(),
																			) ||
																		String(
																			option?.value,
																		)
																			?.toLowerCase()
																			?.startsWith(
																				input.toLowerCase(),
																			)
																	}
																	options={custodianCompanies.map(
																		company => ({
																			label: company.name,
																			value: company.id,
																		}),
																	)}
																	showSearch
																/>
															</Form.Item>
														</Col>
														<Col span={8}>
															<Form.Item
																name="fund_administrator_id"
																label="Empresa Administradora"
																rules={[
																	{
																		required:
																			true,
																		message:
																			'Campo obrigatório',
																	},
																]}
															>
																<Select
																	placeholder="Selecione"
																	filterOption={(
																		input,
																		option,
																	) =>
																		option?.label
																			?.toLowerCase()
																			?.startsWith(
																				input.toLowerCase(),
																			) ||
																		String(
																			option?.value,
																		)
																			?.toLowerCase()
																			?.startsWith(
																				input.toLowerCase(),
																			)
																	}
																	options={adminCompanies.map(
																		company => ({
																			label: company.name,
																			value: company.id,
																		}),
																	)}
																	showSearch
																/>
															</Form.Item>
														</Col>
													</>
												)}
											</Row>
										)}
									</FormItem>
								</Col>
							</Row>
						)}
					</>
				)}
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<FormItem
							style={{ padding: 0, margin: 0 }}
							shouldUpdate={(prevValues, currentValues) =>
								prevValues.taxpayer_id !==
								currentValues.taxpayer_id
							}
						>
							{({ getFieldValue }) => {
								return (
									<Form.Item
										name="taxpayer_id"
										label="CNPJ"
										normalize={e => normalizeCnpj(e)}
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
											cnpjRule,
										]}
									>
										<Input
											disabled={loading}
											placeholder="00.000.000/0000-00"
											onBlur={() => {
												const taxpayerId =
													getFieldValue(
														'taxpayer_id',
													);
												if (
													validateCnpj(taxpayerId) &&
													!loading
												) {
													getCompanyByTaxpayerId(
														serializeOnlyNumbers(
															taxpayerId,
														),
													);
												}
											}}
											suffix={
												loading ? (
													<Spinner>
														<PiSpinnerBold
															style={{
																fontSize: 24,
															}}
														/>
													</Spinner>
												) : null
											}
										/>
									</Form.Item>
								);
							}}
						</FormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={12}>
						<Form.Item
							name="corporate_name"
							label="Razão Social"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								fullCompanyNameRule('corporate_name'),
							]}
						>
							<Input placeholder="Digite aqui" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="name"
							label="Nome Fantasia"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Digite aqui" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={8}>
						<Form.Item
							name="foundation_date"
							label="Data de Constituição"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								maxTodayDateRule('foundation_date'),
							]}
						>
							<DatePicker
								placeholder="DD/MM/AAAA"
								format="DD/MM/YYYY"
								style={{ height: '2.5rem', width: '100%' }}
							/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							name="email_address"
							label="E-mail"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								emailRule('email_address'),
							]}
						>
							<Input placeholder="Digite aqui" />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							name="phone"
							label="Telefone"
							normalize={e => normalizePhone(e)}
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								phoneRule('phone'),
							]}
						>
							<Input placeholder="(00) 00000-0000" />
						</Form.Item>
					</Col>
				</Row>
				<Col span={24}>
					<Form.Item
						name="cnae"
						label="CNAE"
						rules={[
							{
								required: true,
								message: 'Campo obrigatório',
							},
						]}
					>
						<Select
							style={{ width: '100%', maxWidth: '45rem' }}
							showSearch
							filterOption={(input, option) =>
								option?.label
									?.toLowerCase()
									?.includes(input.toLowerCase()) ||
								String(option?.value)
									?.toLowerCase()
									?.includes(input.toLowerCase())
							}
							placeholder="Selecione o CNAE"
							options={parsedCnaeData}
						/>
					</Form.Item>
				</Col>
				<Row gutter={[16, 16]}>
					<Col span={12}>
						<Form.Item
							name="average_monthly_revenue"
							label="Faixa de Faturamento"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Select
								placeholder="Selecione a faixa de faturamento"
								options={companyRevenueOptions.map(option => ({
									label: option.label,
									value: option.value,
								}))}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</>
	);
};

export default LegalPersonContainer;
