import {
	Button,
	Col,
	Dropdown,
	Flex,
	Row,
	Table,
	Tooltip,
	notification,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { FilterButton } from 'components/FilterButton';
import { TextM, TextS } from 'components/Text';
import { LuSearch } from 'react-icons/lu';
import theme from 'theme/theme';
import { DropdownWrapper, FilterCol } from '../EscrowApprovals/styles';
import { EscrowLeadsFiltersType } from './types';
import { EscrowLeadsFilters } from './EscrowLeadsFilters.tsx/EscrowLeadsFilters';
import {
	AccountContent,
	AccountStatus,
	EAccountStatusUpdate,
	IIUpdateAccountStatus,
} from 'types/Account';
import { AiOutlineStop } from 'react-icons/ai';
import { IconWrapper, StyledSelect } from './styles';
import { PaginationInfo } from 'types/List';
import { ColumnsType } from 'antd/es/table';
import { MenuProps } from 'antd/lib';
import { Link, useNavigate } from 'react-router-dom';
import { EyeIcon } from 'components/Icons/Eye';
import { FileWarningIcon } from 'components/Icons/FileWarning';
import { ETenantType, PersonType } from 'types/Company';
import { useEscrowAccountsContext } from 'modules/escrow/context/EscrowAccounts.context';
import { truncateString } from 'helpers/formaters';
import dayjs from 'dayjs';
import { StarIcon } from 'components/Icons/Star';
import { normalizeBankAccount } from 'helpers/normalizers';
import { AccountsService } from 'modules/escrow/services';
import { useMutation, useQuery } from '@tanstack/react-query';
import EventConfirmationModal from '../EscrowDetails/EventConfirmationModal/EventConfirmationModal';
import { AccountStatusDescription } from 'constants/account';
import { queryClient } from 'network/query';
import { ApiError } from 'types/ApiError';
import { InformationModal } from 'components/InformationModal';
import { CheckmarkIcon } from 'components/Icons/Checkmark';
import { AccessType } from 'types/Access';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import {
	getAccountRouteType,
	useIsEscrowAccountRoute,
} from 'modules/escrow/utils/adminRoute';
import { contaLivre } from 'modules/escrow/constants/routes';

export function EscrowLeads() {
	const [api, contextHolder] = notification.useNotification();
	const navigate = useNavigate();
	const { setIsEditingAccount } = useEscrowAccountsContext();
	const { type } = useCurrentProfile();

	const [selectedAccountId, setSelectedAccountId] = useState('');
	const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
	const [isOpenConfirmationModal, setIsOpenConfirmationModal] =
		useState(false);
	const [selectedStatus, setSelectedStatus] = useState<AccountStatus>(
		AccountStatus.AWAITING_BACKOFFICE_ANALYSIS,
	);
	const [isOpenFiltersModal, setIsOpenFiltersModal] = useState(false);
	const [searchFilters, setSearchFilters] = useState<EscrowLeadsFiltersType>(
		{} as EscrowLeadsFiltersType,
	);
	const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
		currentPage: 1,
		pageSize: 10,
	});

	const accountRoute = !useIsEscrowAccountRoute() ? contaLivre : 'escrow';
	const accountType = getAccountRouteType();

	const { data, isLoading, refetch } = useQuery({
		queryKey: ['leadsList'],
		queryFn: () =>
			AccountsService.getAccounts({
				account_type: accountType,
				page: paginationInfo.currentPage,
				size: paginationInfo.pageSize,
				status: selectedStatus as unknown as AccountStatus,
				account: searchFilters.account,
				creator_name: searchFilters.creator_name,
				requested_by_user: true,
			}),
	});

	const { mutate: updateStatus, isPending } = useMutation<
		void,
		ApiError,
		IIUpdateAccountStatus
	>({
		mutationFn: ({ id, transition_event, reason }) => {
			return AccountsService.updateAccountStatus(id, {
				transition_event: transition_event,
				reason: reason,
			});
		},
		onSuccess: (_, data) => {
			setIsOpenConfirmationModal(false);
			setIsOpenSuccessModal(true);
			refetch();
			queryClient.refetchQueries({
				queryKey: ['accountDetails', data.id],
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema.',
			});
		},
	});

	const createActionItems = (item: AccountContent): MenuProps['items'] => {
		const defaultItems: MenuProps['items'] = [];

		if (
			type === AccessType.FINANCIAL_INSTITUTION &&
			[AccountStatus.AWAITING_BACKOFFICE_ANALYSIS].includes(item.status)
		) {
			defaultItems.push({
				label: 'Iniciar análise',
				key: '0',
				onClick: () => {
					setIsEditingAccount(true);
					navigate(
						`/${accountRoute}/${item.id}/${
							item.person.person_type === PersonType.LEGAL
								? 'details'
								: 'natural-person/details'
						}/info`,
					);
				},
				icon: (
					<IconWrapper>
						<EyeIcon size={20} color={theme.textSecondary} />
					</IconWrapper>
				),
			});
		}

		if (
			type === AccessType.FINANCIAL_INSTITUTION &&
			[AccountStatus.AWAITING_DOCUMENTS_UPDATE].includes(item.status)
		) {
			defaultItems.push({
				label: 'Documentação pendente',
				key: '1',
				icon: (
					<IconWrapper>
						<FileWarningIcon
							size={19}
							color={theme.textSecondary}
						/>
					</IconWrapper>
				),
				onClick: () => {
					setSelectedAccountId(item.id);
					setIsOpenConfirmationModal(true);
				},
			});
		}

		if (
			type !== AccessType.FINANCIAL_INSTITUTION &&
			[AccountStatus.AWAITING_DOCUMENTS_UPDATE].includes(item.status)
		) {
			defaultItems.push({
				label: 'Documentação pendente',
				key: '2',
				icon: (
					<IconWrapper>
						<FileWarningIcon
							size={19}
							color={theme.textSecondary}
						/>
					</IconWrapper>
				),
				onClick: () => {
					navigate(`/${accountRoute}/edit/${item.id}`);
				},
			});
		}

		if (
			type !== AccessType.FINANCIAL_INSTITUTION &&
			[AccountStatus.AWAITING_BACKOFFICE_ANALYSIS].includes(item.status)
		) {
			defaultItems.push({
				label: 'Detalhes da conta',
				key: '0',
				onClick: () => {
					setIsEditingAccount(true);

					navigate(
						`/${accountRoute}/${item.id}/${
							item.person.person_type === PersonType.LEGAL
								? 'details'
								: 'natural-person/details'
						}/info`,
					);
				},
				icon: (
					<IconWrapper>
						<EyeIcon size={20} color={theme.textSecondary} />
					</IconWrapper>
				),
			});
		}

		return defaultItems;
	};

	const columns: ColumnsType<AccountContent> = [
		{
			title: 'Nome',
			width: '20%',
			render: (lead: AccountContent) => (
				<div>
					<TextM weight="bold" color={theme.textSecondary}>
						{lead.person.name || ''}
					</TextM>
				</div>
			),
		},
		{
			title: 'Tipo de conta',
			width: '10%',
			render: (lead: AccountContent) => (
				<div>
					<TextM
						weight="bold"
						color={theme.textSecondary}
					>{`${lead.person.person_type === PersonType.LEGAL ? 'PJ' : 'PF'}`}</TextM>
				</div>
			),
		},
		{
			title: 'Solicitado em',
			width: '10%',
			render: (lead: AccountContent) => (
				<div>
					{lead?.created_at ? (
						<>
							<TextM
								weight="bold"
								color={theme.textSecondary}
							>{`${dayjs(lead.created_at).format(
								'DD/MM/YYYY',
							)}`}</TextM>
							<TextS color={theme.textGray} weight="normal">
								{`às ${dayjs(lead.created_at).format('HH:mm:ss')}`}
							</TextS>
						</>
					) : (
						'-'
					)}
				</div>
			),
		},
		{
			title: 'Conta beneficiária',
			width: '10%',
			render: (lead: AccountContent) => (
				<Flex align="center">
					<div style={{ marginRight: '5px', width: '1.3rem' }}>
						{lead.tenant_type !== ETenantType.DEFAULT && (
							<StarIcon size={20} />
						)}
					</div>
					<TextM weight="normal" color={theme.textGray}>
						{lead.account
							? normalizeBankAccount(lead.account)
							: '-'}
					</TextM>
				</Flex>
			),
		},
		{
			title: 'Comentário',
			width: '10%',
			render: (lead: AccountContent) => {
				return lead?.reason || lead?.error ? (
					<Tooltip title={lead?.reason || lead?.error}>
						<TextS weight="normal" color={theme.textGray}>
							{truncateString(
								lead?.reason || lead?.error || '',
								15,
							) ?? '-'}
						</TextS>
					</Tooltip>
				) : (
					<div>-</div>
				);
			},
		},
		{
			title: '',
			width: '5%',
			align: 'end',
			render: (lead: AccountContent) => {
				return [
					AccountStatus.AWAITING_BACKOFFICE_ANALYSIS,
					AccountStatus.AWAITING_DOCUMENTS_UPDATE,
				].includes(lead.status) ? (
					<Dropdown
						disabled={isLoading}
						menu={{ items: createActionItems(lead) }}
						trigger={['click']}
					>
						<Button
							type="link"
							style={{
								color: theme.text,
								fontWeight: 'bold',
								fontSize: '1.3rem',
								lineHeight: '1rem',
								letterSpacing: '0.09rem',
							}}
							loading={isLoading}
						>
							...
						</Button>
					</Dropdown>
				) : (
					/* Visualizar seguirá para a page de Detalhes em modo Não Editável */
					<Link
						to={`/${accountRoute}/${lead.id}/${
							lead.person.person_type === PersonType.LEGAL
								? 'details'
								: 'natural-person/details'
						}/info`}
					>
						Visualizar
					</Link>
				);
			},
		},
	];

	const clearFilters = () => {
		setSelectedStatus(AccountStatus.AWAITING_BACKOFFICE_ANALYSIS);
		setPaginationInfo({
			currentPage: 1,
			pageSize: 10,
		});
		setSearchFilters({} as EscrowLeadsFiltersType);
	};
	const setWaitingUpdateHandler = (reason: string) => {
		updateStatus({
			id: selectedAccountId || '',
			transition_event:
				EAccountStatusUpdate.BACKOFFICE_ANALYSIS_REQUEST_DOCUMENTS,
			reason: reason || '',
		});
	};

	const handleSelect = useCallback(
		(value: AccountStatus) => {
			setSelectedStatus(value);
		},
		[setSelectedStatus],
	);

	useEffect(() => {
		refetch();
	}, [selectedStatus, paginationInfo, searchFilters, refetch]);

	return (
		<>
			{contextHolder}

			<Row justify="space-between" style={{ marginBottom: '1rem' }}>
				<Col>
					<FilterCol>
						<Row style={{ gap: '1rem' }}>
							<FilterCol>
								<TextS>Status</TextS>
								<StyledSelect
									value={selectedStatus}
									defaultValue={selectedStatus}
									optionRender={option => {
										return <div>{option.label}</div>;
									}}
									dropdownRender={menu => {
										return (
											<DropdownWrapper>
												{menu}
											</DropdownWrapper>
										);
									}}
									options={Object.keys(
										AccountStatusDescription,
									).map(key => ({
										label: AccountStatusDescription[
											key as AccountStatus
										],
										value: key,
									}))}
									onSelect={value =>
										handleSelect(value as AccountStatus)
									}
								/>
							</FilterCol>
							<FilterCol>
								<FilterButton
									icon={<LuSearch size={18} />}
									onClick={() => setIsOpenFiltersModal(true)}
								>
									<TextS color={theme.white}>
										Busca avançada
									</TextS>
								</FilterButton>
							</FilterCol>
							<FilterCol>
								<FilterButton
									onClick={clearFilters}
									variation="secondary"
									icon={
										<AiOutlineStop
											size={18}
											color={theme.white}
										/>
									}
									disabled={
										!Object.keys(searchFilters).length
									}
								>
									<TextS>Limpar filtros</TextS>
								</FilterButton>
							</FilterCol>
						</Row>
					</FilterCol>
				</Col>
			</Row>

			<Table
				columns={columns}
				rowKey={record => record.id}
				dataSource={data?.content}
				loading={isLoading || isPending}
				pagination={{
					total: data?.total_elements || 0,
					showTotal(total) {
						const currentSize =
							paginationInfo.currentPage *
							paginationInfo.pageSize;
						return `${currentSize > total ? total : currentSize} de ${total}`;
					},
					pageSizeOptions: ['10', '30', '60', '90'],
					showSizeChanger: true,
					pageSize: paginationInfo.pageSize,
					current: paginationInfo.currentPage,
					onChange(page, pageSize) {
						setPaginationInfo({
							currentPage: page,
							pageSize,
						});
					},
				}}
			/>

			<EscrowLeadsFilters
				filters={searchFilters}
				isOpen={isOpenFiltersModal}
				onClose={() => setIsOpenFiltersModal(false)}
				onApply={filters => {
					setSearchFilters(filters);
				}}
			/>

			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isOpenSuccessModal}
				message="A conta foi enviada para revisão de documentação."
				title="Pendência sinalizada com sucesso!"
				icon={<CheckmarkIcon />}
				onClose={() => setIsOpenSuccessModal(false)}
			/>

			{isOpenConfirmationModal && (
				<EventConfirmationModal
					closeModal={() => setIsOpenConfirmationModal(false)}
					info={{
						buttonText: 'Sinalizar pendência de documentação',
						infoPrimary:
							'Sinalizar que a abertura da conta possui documentos pendentes?',
						modalTitle: 'Sinalizar pendência de documentação',
						onClick: () => {},
						isDanger: true,
						disableIfEmptyReason: true,
						inputTextTitle: 'Documentos pendentes',
					}}
					hasInputText
					isLoading={isLoading}
					confirmHandler={reason =>
						setWaitingUpdateHandler(reason || '')
					}
				/>
			)}
		</>
	);
}
