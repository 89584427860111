import React from 'react';

export const EscrowAccountOutlinedIcon: React.FC<{
	color?: string;
	size?: number;
}> = ({ color, size }) => (
	<svg
		width={size ?? '24'}
		height={size ?? '24'}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM7 13H16V15H12V18L7 13ZM12 9V6L17 11H8V9H12Z"
			fill={color ?? '#7664FA'}
		/>
	</svg>
);

export const EscrowAccountIcon: React.FC<{ color?: string }> = ({ color }) => (
	<svg
		width="24"
		height="25"
		viewBox="0 0 24 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12 22.2402C6.477 22.2402 2 17.7632 2 12.2402C2 6.71723 6.477 2.24023 12 2.24023C17.523 2.24023 22 6.71723 22 12.2402C22 17.7632 17.523 22.2402 12 22.2402ZM12 9.24023H8V11.2402H17L12 6.24023V9.24023ZM7 13.2402L12 18.2402V15.2402H16V13.2402H7Z"
			fill={color ?? '#7664FA'}
		/>
	</svg>
);
