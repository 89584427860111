import styled from 'styled-components';

export const StyledMenuContainer = styled.div`
	background: ${({ theme }) => theme.background};
	padding: 2rem;
	height: 100vh;
	bottom: 0;
`;

export const StyledCard = styled.div`
	box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	cursor: pointer;
	transition: transform 0.4s ease;
	padding: 0;
	width: 100%;

	.ant-card {
		padding: 6px 8px;
		border: none;

		.ant-card-body {
			padding: 10px;
		}
	}

	.cardIcon {
		border: 1px solid ${({ theme }) => theme.primary};
		background: ${({ theme }) => theme.backgroundVariant};
		display: flex;
		width: 40px;
		padding: 9px 10px 11px 10px;
		justify-content: center;
		align-items: center;
		border-radius: 10px;
		box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
		margin-bottom: 1rem;
	}

	&:hover {
		transform: translateY(-10px);
		border: 1.5px solid ${({ theme }) => theme.primary};

		p {
			color: ${({ theme }) => theme.primary};
		}

		.hoverArrowIcon {
			color: ${({ theme }) => theme.primary};
			margin-right: -10px;
		}

		.cardIcon {
			background: ${({ theme }) => theme.background};

			img {
				mix-blend-mode: plus-lighter;
			}
		}
	}

	/* Garante que o conteúdo interno não receba transformações individuais */
	> * {
		will-change: transform;
	}
`;
