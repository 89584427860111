import { IListFilters } from 'types/List';

export enum ECnabExportFileType {
	TXT = 'TXT',
	CSV = 'CSV',
	PDF = 'PDF',
}

export interface IReturnedCnab {
	created_at: string;
	status?: ECnabStatus;
	id: string;
	file_types: ECnabExportFileType;
	quantity?: number | string;
}

export enum ECnabStatus {
	ERROR = 'ERROR',
	SUCCESS = 'SUCCESS',
}

export enum ECnabStatusLabel {
	ERROR = 'Erro ao gerar arquivo',
	SUCCESS = 'Arquivo gerado com sucesso',
}

export interface ICnabFilters extends IListFilters {
	created_at: string;
}

export interface ICnabExport {
	file_type: ECnabExportFileType;
}
