import { useNavigate } from 'react-router-dom';
import React, { useContext, useMemo } from 'react';
import { EscrowApprovals } from './pages/EscrowApprovals';
import { EscrowAccounts } from './pages/EscrowAccounts';
import { LuPlusCircle } from 'react-icons/lu';
import { Tabs } from 'components/Tabs';
import { PageHeader } from 'components/PageHeader';
import { Heading1 } from 'components/Heading1';
import { PageWrapper } from 'components/PageWrapper';
import { EscrowRoutes } from './constants/routes';
import { BreadcrumbContainer } from 'components/Breadcrumb';
import { PageActions } from 'components/PageActions';
import { Button } from 'antd';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessPermission, AccessType } from 'types/Access';
import { TabElement } from 'components/Tabs/Tabs';
import { AbilityContext } from 'modules/core/context/AbilityContext';
import { EscrowLeads } from './pages/EscrowLeads/EscrowLeads';
import {
	getAccountRouteTypeIcon,
	getAccountRouteTypeName,
	useIsEscrowAccountRoute,
} from './utils/adminRoute';

const EscrowPage = () => {
	const { type, permissions } = useCurrentProfile();
	const navigate = useNavigate();
	const ability = useContext(AbilityContext);
	const isEscrowRoute = useIsEscrowAccountRoute();

	const breadcrumb = [
		{
			href: EscrowRoutes.ESCROW_BASE,
			title: (
				<BreadcrumbContainer>
					{getAccountRouteTypeIcon()}
					<span>{getAccountRouteTypeName()}</span>
				</BreadcrumbContainer>
			),
		},
	];

	const tabElements = useMemo<TabElement[]>(() => {
		const tabs = [
			{
				title: 'Contas',
				content: <EscrowAccounts />,
				path: 'accounts',
				breadcrumb,
			},
		];

		if (
			ability.can('REVIEW', 'POSTING') ||
			ability.can('VIEW', 'POSTING') ||
			type === AccessType.FINANCIAL_INSTITUTION
		) {
			tabs.push({
				title: 'Aprovações',
				content: <EscrowApprovals />,
				path: 'approvals',
				breadcrumb,
			});
		}

		if (
			ability.can('CREATE', 'ACCOUNT') ||
			type === AccessType.FINANCIAL_INSTITUTION
		) {
			tabs.push({
				title: 'Solicitações de abertura',
				content: <EscrowLeads />,
				path: 'leads',
				breadcrumb,
			});
		}
		return tabs;
	}, [ability, type]);

	const createAccountHandler = () => {
		navigate(
			isEscrowRoute ? EscrowRoutes.ESCROW_NEW : EscrowRoutes.ACCOUNT_NEW,
		);
	};

	return (
		<>
			<PageHeader isFluid={true}>
				<Heading1>{getAccountRouteTypeName()}</Heading1>
				{/* HK refatorar permissions e ability  */}
				{(ability.can('CREATE', 'ACCOUNT') ||
					permissions.includes(AccessPermission.CREATE_ACCOUNT) ||
					type === AccessType.FINANCIAL_INSTITUTION) && (
					<PageActions>
						<Button type="primary" onClick={createAccountHandler}>
							Criar Conta
							<LuPlusCircle
								style={{ marginLeft: '0.6rem' }}
								size={18}
							/>
						</Button>
					</PageActions>
				)}
			</PageHeader>
			<PageWrapper isFluid={true}>
				<Tabs elements={tabElements} />
			</PageWrapper>
		</>
	);
};

export default EscrowPage;
