import { AutoComplete, Col, Divider, Flex, Row, Spin } from 'antd';
import { PersonSecondaryIcon } from 'components/Icons/Person';
import { TextM, TextS } from 'components/Text';
import { normalizeCnpj, normalizeCpf } from 'helpers/normalizers';
import { isPersonOrCompany } from 'helpers/validators';
import { CardContainer } from 'modules/escrow/components/DestinationCardInfo/style';
import { useEscrowAccountsContext } from 'modules/escrow/context/EscrowAccounts.context';
import theme from 'theme/theme';
import { EClientType } from 'types/Company';
import { StyledIcon } from '../../styles';
import { BuildingIcon } from 'components/Icons/Building';
import { formatPhoneFromObject } from 'helpers/formaters';
import { Heading2 } from 'components/Heading2';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessType } from 'types/Access';
import { useCallback, useState } from 'react';
import { PersonsService } from 'modules/company/services';
import { debounce } from 'lodash';

interface IBasicInfoComponentProps {
	getTemplateConfig?: (id: string) => void;
}

const BasicInfoComponent = ({
	getTemplateConfig,
}: IBasicInfoComponentProps) => {
	const { company, setCompany } = useEscrowAccountsContext();
	const { type } = useCurrentProfile();

	const [options, setOptions] = useState<IOption[]>();
	const [isLoading, setIsLoading] = useState(false);

	const isIF = type === AccessType.FINANCIAL_INSTITUTION;

	const getClientList = async (searchText: string) => {
		try {
			setIsLoading(true);

			if (!searchText || searchText.trim().length === 0) {
				setOptions([]);
				return;
			}

			const response = await PersonsService.getPersons({
				size: 20,
				search: searchText,
			});

			if (!!response.content.length) {
				return setOptions(
					response.content.map(item => {
						return {
							label: item.name || '-',
							value: `${item.name || '-'} | id: ${item.id}`,
						};
					}),
				);
			}

			return setOptions([
				{
					label: 'Nenhum solicitante encontrado com o nome informado.',
					value: 'Nenhum solicitante encontrado com o nome informado.',
				},
			]);
		} catch (err) {
			console.error('Error get clients', err);
		} finally {
			setIsLoading(false);
		}
	};

	const debouncedGetClientList = useCallback(
		debounce(getClientList, 500),
		[],
	);

	const onSelect = (data: string) => {
		const id = data.split(' | id: ')[1];

		if (getTemplateConfig) {
			getTemplateConfig(id);
		}

		setCompany({
			...company,
			created_by_person_id: id || undefined,
		});
	};

	const isPFContent = (
		<>
			<Flex align="center">
				<StyledIcon>
					<PersonSecondaryIcon color={theme.white} size={18} />
				</StyledIcon>
				<TextM weight="bold" color={theme.text}>
					{company?.name || '*****'}
				</TextM>
			</Flex>

			<Row gutter={[16, 16]} style={{ marginTop: '2rem' }}>
				<Col span={8}>
					<TextS color={theme.textGray}>CPF:</TextS>
					<TextS weight="bold" color={theme.textSecondary}>
						{normalizeCpf(company?.taxpayer_id || '') || '*****'}
					</TextS>
				</Col>
				<Col span={8}>
					<TextS color={theme.textGray}>Nome Completo:</TextS>
					<TextS weight="bold" color={theme.textSecondary}>
						{company?.name || '*****'}
					</TextS>
				</Col>
				<Col span={8}>
					<TextS color={theme.textGray}>E-mail:</TextS>
					<TextS weight="bold" color={theme.textSecondary}>
						{company?.email_address || '*****'}
					</TextS>
				</Col>
			</Row>
		</>
	);

	const isPJContent = (
		<>
			<Flex align="center">
				<StyledIcon>
					<BuildingIcon color={theme.white} size={18} />
				</StyledIcon>
				<TextM weight="bold" color={theme.text}>
					{company?.name || '*****'}
				</TextM>
			</Flex>

			<Row gutter={[16, 16]} style={{ marginTop: '2rem' }}>
				<Col span={8}>
					<TextS color={theme.textGray}>CNPJ:</TextS>
					<TextS weight="bold" color={theme.textSecondary}>
						{normalizeCnpj(company?.taxpayer_id || '') || '*****'}
					</TextS>
				</Col>
				<Col span={8}>
					<TextS color={theme.textGray}>Razão social:</TextS>
					<TextS weight="bold" color={theme.textSecondary}>
						{company?.corporate_name || '*****'}
					</TextS>
				</Col>
				<Col span={8}>
					<TextS color={theme.textGray}>Nome fantasia:</TextS>
					<TextS weight="bold" color={theme.textSecondary}>
						{company?.name || '*****'}
					</TextS>
				</Col>
			</Row>

			<Row gutter={[16, 16]} style={{ marginTop: '2rem' }}>
				<Col span={8}>
					<TextS color={theme.textGray}>Telefone:</TextS>
					<TextS weight="bold" color={theme.textSecondary}>
						{typeof company?.phone === 'string'
							? company?.phone
							: formatPhoneFromObject(company?.phone) || '*****'}
					</TextS>
				</Col>
				<Col span={8}>
					<TextS color={theme.textGray}>E-mail:</TextS>
					<TextS weight="bold" color={theme.textSecondary}>
						{company?.email_address || '*****'}
					</TextS>
				</Col>
			</Row>
		</>
	);

	return (
		<>
			<Divider />
			{isIF && (
				<div style={{ marginBottom: '2rem' }}>
					<Heading2>
						Solicitante:{' '}
						<small>
							(opcional){' '}
							{isLoading && (
								<Spin
									size="small"
									style={{ marginLeft: '1rem' }}
								/>
							)}
						</small>
					</Heading2>
					<Flex>
						<AutoComplete
							placeholder="Digite aqui"
							options={options}
							style={{
								width: '100%',
							}}
							allowClear
							onSelect={onSelect}
							onSearch={debouncedGetClientList}
						/>
					</Flex>
				</div>
			)}

			<Heading2>Cliente:</Heading2>
			<CardContainer>
				{company?.taxpayer_id &&
				isPersonOrCompany(company?.taxpayer_id) === EClientType.PF
					? isPFContent
					: isPJContent}
			</CardContainer>
		</>
	);
};

export default BasicInfoComponent;
